
import { defineComponent, onMounted, Ref, ref } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import {
  ProjectMeta,
  Project,
  RmmSettings,
  RmmSettingsMeta,
  TerminalSettings,
  TerminalSettingsMeta
} from './types'
import axios from '@/axios'
import { DefaultInput, DefaultSelect, FormErrors, LineLoader } from 'apptimizm-ui'
import moment from 'moment'
import WorkingWithProject from './working-with-project/index.vue'
import Statistic from './statistic/index.vue'
import { mutations } from '../../store/index'
import { storageName4ExporDocuments } from './working-with-project/store/storageName4ExporDocuments'
import CheckPasswordModal from './check-password-modal/index.vue'

export default defineComponent({
  setup () {
    const projectMeta = new ProjectMeta()
    const project: Ref<Project> = ref(new Project()) as Ref<Project>
    const rmmSettings: Ref<RmmSettings> = ref(new RmmSettings())
    const rmmSettingsMeta = new RmmSettingsMeta()
    const { params, meta } = useRoute()
    const { push } = useRouter()
    const terminalSettings: Ref<TerminalSettings> = ref(new TerminalSettings())
    const terminalSettingsMeta = new TerminalSettingsMeta()
    const formErrors = ref<FormErrors>(new FormErrors({}))
    const isLoading = ref<{ [code: string]: boolean }>({
      loadFile: false,
      celeryHandler: false,
      main: false
    })

    onMounted(async () => {
      isLoading.value.main = true
      try {
        project.value = projectMeta.load((await axios.get(projectMeta.endpoint + params.id)).data)
        rmmSettings.value = rmmSettingsMeta.load((await axios.get(
          rmmSettingsMeta.endpoint + project.value.rmmSettings)
        ).data)
        terminalSettings.value = terminalSettingsMeta.load((await axios.get(
          terminalSettingsMeta.endpoint + project.value.terminalSettings)
        ).data)
        document.title = meta.title + project.value.name
        storageName4ExporDocuments.getStorageNames(project.value.id)
      } catch (e) { push('/projects') }
      isLoading.value.main = false
    })

    // Открытие настроек после подтверждения пароля
    const showSettings = ref(false)

    const isShowCheckPasswordModal = ref(false)

    function closeSettingsOrCheckPassword () {
      if (showSettings.value) {
        showSettings.value = false
        return
      }

      isShowCheckPasswordModal.value = true
    }
    //

    const submit = async () => {
      const rmmResponse = (await axios.put(
        rmmSettingsMeta.endpoint + rmmSettings.value.id,
        rmmSettingsMeta.dump(rmmSettings.value))
      ).data
      const terminalResponse = (await axios.put(
        terminalSettingsMeta.endpoint + terminalSettings.value.id,
        terminalSettingsMeta.dump(terminalSettings.value))
      ).data

      const projectData = projectMeta.dump(project.value)

      if (projectData.template) delete projectData.template
      if (projectData.auto_assign_enbale) delete projectData.auto_assign_enbale

      const projectResponse = (await axios.put(
        projectMeta.endpoint + params.id, projectData
      )).data

      if (rmmResponse?.errors) {
        formErrors.value = rmmSettingsMeta.errors(rmmResponse.errors)
        mutations.pushNotification('Ошибка сохранения Параметров РММ', true)
      } else mutations.pushNotification('Настройки Параметров РММ успешно сохранены')
      if (terminalResponse?.errors) {
        formErrors.value = terminalSettingsMeta.errors(terminalResponse.errors)
        mutations.pushNotification('Ошибка сохранения Параметров Терминала', true)
      } else mutations.pushNotification('Настройки Параметров Терминала успешно сохранены')
      if (projectResponse?.errors) {
        formErrors.value = projectMeta.errors(projectResponse.errors)
        mutations.pushNotification('Ошибка сохранения Параметров Учет без УК', true)
      } else mutations.pushNotification('Настройки Параметров Учет без УК успешно сохранены')
    }

    const updateTemplateInProject = async (templateId: string|null, type: 'template'|'templateExport') => {
      project.value[type] = templateId
      if (templateId !== null) {
        await axios.patch(projectMeta.endpoint + params.id, projectMeta.dump(project.value))
      }
    }

    const issuingTaskList = [{ name: 'Наименее загруженному пользователю', value: 'least_loaded_user' }, { name: 'Текущему пользователю', value: 'current_user' }]
    const unknownBarcodeList = [{ name: 'Разрешать', value: 'allow' }, { name: 'Запрещать', value: 'disallow' }]
    const recalculationDiscrepancyList = [{ name: 'Скан.', value: 'scan' }, { name: 'УК.', value: 'controller' }]

    return () => (
      <div style="min-width: 1450px;">
        <div class="page-top-panel small">
          <h1>{project.value.name}</h1>
          <p>{rmmSettings.value.extendedTasks}</p>
        </div>
        <div class='line-loader-container'>
          {isLoading.value.main && <LineLoader/>}
        </div>
        {!isLoading.value.main && <div class="layout settings">
          <div class="row">
            <div class="col">
              <p class="col-title col-title--small">Адрес</p>
              <p>{project.value.address}</p>
            </div>
            <div class="col">
              <p class="col-title col-title--small">Дата инвентаризации</p>
              <p>{moment(project.value.createdAt).locale('ru').format('D MMMM YYYY')}</p>
            </div>
            <div class="col">
              <p class="col-title col-title--small">Ответственный менеджер</p>
              <p>{project.value.manager.lastName} {project.value.manager.firstName} {project.value.manager.middleName}</p>
            </div>
          </div>
          <button onClick={closeSettingsOrCheckPassword} class="gear"/>
          {isShowCheckPasswordModal.value && <CheckPasswordModal
            rmmSettings={rmmSettings.value}
            closeModal={() => { isShowCheckPasswordModal.value = false }}
            confirmCallback={() => { showSettings.value = true }}
          />}
          <div class={showSettings.value ? 'settings-block' : 'settings-block hide'}>
            <div class="settings-item">
              <p class="col-title">Параметры РММ</p>
              <div class="row mt-3">
                <label>
                  <DefaultInput
                    class={rmmSettings.value.password ? 'input-placeholder-up' : ''}
                    placeholder="Пароль администратора"
                    modelValue={rmmSettings.value.password}
                    onValueChange={(v: string) => { rmmSettings.value.password = v }}
                    errors={formErrors.value.forField('password')}
                  />
                </label>
              </div>
            </div>
            <div class="settings-item">
              <p class="col-title">Параметры терминала</p>
              <div class="row">
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.issuingTask}
                  idKey="value"
                  titleKey="name"
                  placeholder="Выдача задания для УК"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.issuingTask = { name: v.name, value: v.id } }}
                  items={issuingTaskList.map(v => ({ id: v.value, name: v.name }))}
                />
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.unknownBarcode}
                  idKey="name"
                  titleKey="name"
                  placeholder="Неизвестный штрих-код"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.unknownBarcode = { name: v.name, value: v.id } }}
                  items={unknownBarcodeList.map(v => ({ id: v.value, name: v.name }))}
                />

                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.recalculationDiscrepancy}
                  idKey="name"
                  titleKey="name"
                  placeholder="Пересчет при расхождении"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.recalculationDiscrepancy = { value: v.id, name: v.name } }}
                  items={recalculationDiscrepancyList.map(v => ({ id: v.value, name: v.name }))}
                />
              </div>
              <div class="row right">
                <button class="primary-button" onClick={submit}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>}
        {!isLoading.value.main && <div class="row statistic mt-3">
          <Statistic
            statistic={project.value.statistic}
          />
          <WorkingWithProject
            updateTemplateInProject={updateTemplateInProject}
            projectId={params.id as string}
            templateId={project.value.template}
            templateExportId={project.value.templateExport}
          />
        </div>}
      </div>
    )
  }
})
